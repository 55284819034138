<template>
        <b-row>
            <b-col md="12" sm="12">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 py-5">
                      <h3 class="card-title">
                        <span class="card-label font-weight-bolder text-dark" style="display: flex; align-items: center;">
                          Nuestros Clientes
                          <span class="text-muted font-weight-bold font-size-sm">
                             <b-form-select size="sm" v-model="perPage" :options="PageOptions" :clearable="false" class="ml-2" />
                          </span>
                        </span>
                      </h3>
                      <div class="pr-5 mt-3">
                        <b-row style="display: flex;">
                            <b-form-input
                            style="width: 300px;"
                              v-model="searchTerm"
                              class="d-inline-block mr-5"
                              placeholder="Buscar Cliente ..."
                              @input="handleSearch"
                            />
                            <b-button 
                              v-b-toggle.add-client-sidebar
                              @click="Create"
                            >
                              Agregar Cliente
                            </b-button>
                        </b-row>
                      </div>
                    </div>
                    <div class="card-body py-0">
                      <b-table
                        ref="tableInvitados"
                        :items="itemClients"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        responsive
                        @sort-changed="onSortChange"
                        style="position: relative !important; height: 500px !important;"
                      >
                        
                        <template #cell(name)="data">
                            <b-media vertical-align="center">
                              <template #aside>
                                <b-avatar
                                  class="border"
                                  size="32"
                                  button
                                />
                              </template>
                              <!-- @click="NavigateUserProfile(data.item.IdUser)" -->
                              <span class="font-weight-bold text-nowrap">
                                {{ data.value }}
                              </span>
                            </b-media>
                        </template>

                        <template #cell(phone)="data">
                          <span class="text-nowrap">
                            {{ data.value }}
                          </span>
                        </template>
                        
                        <template #cell(email)="data">
                          <span class="text-nowrap">
                            {{ data.value }}
                          </span>
                        </template>
                  
                        <template #cell(Actions)="data">
                          <span class="text-nowrap">
                            <!-- <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-sm pulse"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <i class="las la-link"></i>
                              </span>
                            </a> -->
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-hover-bedo btn-sm mx-3 pulse"
                              v-b-toggle.add-client-sidebar
                              @click="getDataofClient(data.item.idClient)"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                <i class="las la-pencil-alt"></i>
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-hover-bedo btn-sm pulse"
                              @click="DeleteClient(data.item.idClient)"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                <inline-svg src="media/svg/icons/General/Trash.svg" />
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                          </span>
                        </template>
                  
                      </b-table>
                  
                      <div class="border-0 py-5">
                        <b-row>
                          
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                          >
                            <span class="text-muted">
                                Showing {{ perPage }} of {{ FullCountEntries }} entries
                            </span>
                          </b-col>
                         
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                          >
                            <b-pagination
                              pills
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              :current-page="currentPage"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                              @change="handleChangePage"
                            >
                              <template #prev-text>
                                <i class="fa fa-angle-left"></i>
                              </template>
                              <template #next-text>
                                <i class="fa fa-angle-right"></i>
                              </template>
                             
                            </b-pagination>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                </div>
            </b-col>
            <!--begin::View component-->
            <client-sidebar 
              :refresh-data="getClients"
              :get-data="getDataClient"
            />
        </b-row>
</template>

<script>
import clientSidebar from '../SideBars/ClientSidebar.vue'
import { GET_ALL_CLIENTS, DELETE_CLIENT, GET_CLIENT_INFO } from "@/core/services/store/client.module";
import Swal from 'sweetalert2'

export default {
    /* eslint-disable */
    components: {
      clientSidebar,
    },
    data() {
        return {
            itemClients: [],
            fields: [{ key: 'name', label: 'Nombre', sortable: true }, { key: 'phone', label: 'Telefono', sortable: true }, { key: 'email', label: 'Email', sortable: true }, { key: 'Actions', label: 'Acciones' }],
            perPage: 5,
            PageOptions: [5, 10, 25, 50, 100],
            actualpage: 1,
            totalRows: 0,
            currentPage: 1,
            FullCountEntries: 0,
            sortBy: 'name',
            sortDesc: false,
            sortDirection: 'asc',
            searchTerm: '',
            getDataClient: {},
        }
    },
    watch: {
      perPage() {
        this.getClients()
      },
    },
    created() {
      this.getClients()
    },
    methods: {
      Create() {
        this.getDataClient = {}
      },
      getDataofClient(id) {
        this.$store.dispatch(GET_CLIENT_INFO, {
          id
        })
        .then((response) => {
          response.data.data.id = response.data.data.idPerson
          this.getDataClient = response.data.data
        })
      },
      getClients() {
        this.$store.dispatch(GET_ALL_CLIENTS, {
          name: this.searchTerm,
          page: this.actualpage,
          rows: this.perPage,
          orderBy: this.sortBy,
          sortBy: this.sortDirection,
        })
        .then((response) => {
          this.itemClients = response.data.data
          this.FullCountEntries = response.data.data[0].full_count
          this.numPages = response.data.data[0].pages
          this.totalRows = this.FullCountEntries
          this.currentPage = this.actualpage
        })
      },
      DeleteClient(id) {
        Swal.fire({
          title: 'Atención',
          text: '¿Quieres eliminar a este cliente?',
          icon: 'warning',
          confirmButtonText: 'Si',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch(DELETE_CLIENT, {
              id
            })
            .then((response) => {
              Swal.fire(
                '',
                'Cliente Eliminado',
                'success'
              )
              this.getClients()
            })
          }
        })
      },
      handleSearch(searching) {
        this.searchTerm = searching
        this.actualpage = 1
        this.totalRows = 1
        this.getClients()
      },
      handleChangePage(page) {
        this.actualpage = page
        this.getClients()
      },
      onSortChange(params) {
        this.sortBy = params.sortBy
        this.sortDesc = params.sortDesc
        const direction = params.sortDesc === true ? 'desc' : 'asc'
        this.sortDirection = direction
        this.getClients()
      },
      NavigateAccessLevelProfile(id) {
        this.$store.commit('access-level/setIdAccessLevel', id)
        this.$router.push({ name: 'access-level-profile' })
      },
    },
}
</script>

<style scoped>
.GuestsConfirm{
  color: #52DD26 !important; 
  background-color: rgba(82, 221, 38, 0.1) !important;
}

.GuestsDenied{
  color: #DD2C26 !important; 
  background-color: rgba(221, 44, 38, 0.1) !important;
}
</style>