<template>
    <b-sidebar
      id="add-client-sidebar"
      shadow
      right
      bg-variant="white"
      backdrop
      width="400px"
      @hidden="cleanClientSidebar"
    >

    <template #default="{ hide }">
      <div style="padding: 35px;">
        <span class="text-dark-75 font-weight-bolder text-hover-primary" style="font-size: 15px;">
          {{ Object.keys(getData).length === 0 ? 'Agregar Cliente' : 'Editar Cliente' }}
        </span>

        <b-row class="mt-5">
          <b-col sm="12">
            <b-form-group
              label="Nombre"
              label-for="Nombre"
            >
             <b-form-input
               v-model="FirstNameClient"
               type="text"
               class="form-control-merge"
               placeholder="Nombre"
             />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Apellido"
              label-for="Apellido"
            >
             <b-form-input
               v-model="LastNameClient"
               type="text"
               class="form-control-merge"
               placeholder="Apellido"
             />
            </b-form-group>
          </b-col>
         <b-col sm="12">
            <div style="color: red; font-size: 10px;" v-if="PhoneClient.length > 10">(Error) Numero de Telefono mayor a 10 digitos</div>
            <b-form-group
              label="Telefono"
              label-for="Telefono"
            >
            <b-input-group
                class="mb-5"
              >
                <b-input-group-prepend>
                  <b-form-select
                    v-model="Lada"
                    :options="OptionsLada"
                   >
                  </b-form-select>
                </b-input-group-prepend>
                <b-form-input
                  v-model="PhoneClient"
                  type="number"
                  class="form-control-merge"
                  placeholder="--- --- --- -"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <div style="color: red; font-size: 10px;" v-if="Object.keys(getData).length > 0">La edicion no afectara este valor</div>
            <b-form-group
              label="Email"
              label-for="Email"
            >
             <b-form-input
               v-model="Email"
               type="text"
               class="form-control-merge"
               placeholder="ejemplo@gmail.com"
             />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Contraseña"
              label-for="Contraseña"
            >
             <b-form-input
               v-model="Password"
               type="text"
               class="form-control-merge"
               placeholder="********"
             />
            </b-form-group>
          </b-col>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button 
              @click="CreateClient"
            >
              {{ Object.keys(getData).length === 0 ? 'Agregar' : 'Editar' }}
            </b-button>
            <b-button
              ref="closeSideBarClients"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-row>
     </div>
    </template>
    </b-sidebar>
</template>

<script src="assets/plugins/global/plugins.bundle.js"></script>
<script>

// import clientService from './src/core/services/client/clientService'
import { CREATE_CLIENT, EDIT_CLIENT } from "@/core/services/store/client.module";
import Swal from 'sweetalert2'

export default {
  components: {
  },
  data() {
    return{
      FirstNameClient: '',
      LastNameClient: '',
      PhoneClient: '',
      Email: '',
      UserName: '',
      Password: '',
      idClient: 0,
      Lada: '52',
      OptionsLada: [
        { value: '52', text: '+52' },
        { value: '1', text: '+1' },
      ],
    }
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    getData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getData(val) {
      console.log(val);
      if (Object.keys(val).length !== 0) {
        this.FirstNameClient = val.firstName
        this.LastNameClient = val.lastName
        this.PhoneClient = val.phone
        this.Email = val.email
        this.idClient = val.id
        this.Password = ''
        this.Lada = val.lada
      } else {
        this.FirstNameClient = ''
        this.LastNameClient = ''
        this.PhoneClient = ''
        this.Email = ''
        this.idClient = 0
        this.Password = ''
        this.Lada = '52'
      }
    },
  },
  methods: {
    validateInputs() {
      let Text = ''
      if (this.FirstNameClient === '') {
        Text += '- Nombres Faltan<br>'
      } else {
        Text += ''
      }
      if (this.LastNameClient === '') {
        Text += '- Apellidos Faltan<br>'
      } else {
        Text += ''
      }
      if (this.PhoneClient === '') {
        Text += '- Telefono falta<br>'
      } else {
        Text += ''
      }
      if (this.Email === '') {
        Text += '- Email Falta<br>'
      } else {
        Text += ''
      }
      if(this.idClient == 0){
        if (this.Password === '') {
          Text += '- Contraseña Falta<br>'
        } else {
          Text += ''
        }
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
        return false
      } else {
        return true
      }
    },
    CreateClient(){
      if(this.validateInputs()){
        if(Object.keys(this.getData).length === 0){
          this.$store.dispatch(CREATE_CLIENT, {
            firstName: this.FirstNameClient,
            lastName: this.LastNameClient,
            phone: this.PhoneClient,
            email: this.Email,
            password: this.Password,
            lada: this.Lada,
          })
          .then((response) => {
            this.$refs.closeSideBarClients.click()
            this.refreshData()
          }).catch((error) => {
            console.log(error);
          })
        } else {
          this.$store.dispatch(EDIT_CLIENT, {
            id: this.idClient,
            firstName: this.FirstNameClient,
            lastName: this.LastNameClient,
            phone: this.PhoneClient,
            email: this.Email,
            password: this.Password,
            lada: this.Lada,
          })
          .then(() => {
            this.$refs.closeSideBarClients.click()
            this.refreshData()
          })
        }
      }
    },
    cleanClientSidebar(){
      this.FirstNameClient = ''
      this.LastNameClient = ''
      this.PhoneClient = ''
      this.Email = ''
      this.idClient = 0
      this.Password = ''
    }
  }
}
</script>